// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".WarehouseMissingPage_title__3UT1i {\n  margin: 0 20px 20px 0;\n}\n.WarehouseMissingPage_card__yesXg {\n  margin-top: 30px;\n  box-shadow: 0 1px 6px 0 rgba(49,53,59,0.12);\n}\n.WarehouseMissingPage_flexContainer__dL79H {\n  display: flex;\n  justify-content: left;\n  margin-bottom: 30px;\n}\n.WarehouseMissingPage_bottomAction__1QdmI {\n  display: flex;\n  justify-content: space-between;\n  margin-top: 40px;\n}\n.WarehouseMissingPage_select__3-f8e {\n  min-width: 100px;\n  max-width: 350px;\n}\n.WarehouseMissingPage_selectContainer__3tX5A {\n  margin: 0 16px;\n  width: 350px;\n}\n", ""]);
// Exports
exports.locals = {
	"title": "WarehouseMissingPage_title__3UT1i",
	"card": "WarehouseMissingPage_card__yesXg",
	"flexContainer": "WarehouseMissingPage_flexContainer__dL79H",
	"bottomAction": "WarehouseMissingPage_bottomAction__1QdmI",
	"select": "WarehouseMissingPage_select__3-f8e",
	"selectContainer": "WarehouseMissingPage_selectContainer__3tX5A"
};
module.exports = exports;
