// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Page_page__3gU5V {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: right;\n}\n\n.Page_number__3nPaO {\n  margin: 10px;\n}\n", ""]);
// Exports
exports.locals = {
	"page": "Page_page__3gU5V",
	"number": "Page_number__3nPaO"
};
module.exports = exports;
