// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SuperAdminWarehousePage_card__XgPnn {\n  margin: 10px 0px;\n  box-shadow: 0 1px 6px 0 rgba(49, 53, 59, 0.12);\n}\n\n.SuperAdminWarehousePage_flexContainer__25Uab {\n  display: flex;\n  justify-content: space-between;\n}\n", ""]);
// Exports
exports.locals = {
	"card": "SuperAdminWarehousePage_card__XgPnn",
	"flexContainer": "SuperAdminWarehousePage_flexContainer__25Uab"
};
module.exports = exports;
