// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".WarehouseTransferPage_title__lV7Kh {\n  margin: 0 20px 0 0;\n}\n.WarehouseTransferPage_card__oOihF {\n  margin-top: 30px;\n  box-shadow: 0 1px 6px 0 rgba(49,53,59,0.12);\n}\n.WarehouseTransferPage_flexContainer__1p-6C {\n  display: flex;\n  align-items: center;\n}\n.WarehouseTransferPage_bottomAction__1w9x2 {\n  display: flex;\n  justify-content: space-between;\n  margin-top: 40px;\n}\n.WarehouseTransferPage_dropdownLeft__2hrzO {\n  margin-right: 15px;\n}", ""]);
// Exports
exports.locals = {
	"title": "WarehouseTransferPage_title__lV7Kh",
	"card": "WarehouseTransferPage_card__oOihF",
	"flexContainer": "WarehouseTransferPage_flexContainer__1p-6C",
	"bottomAction": "WarehouseTransferPage_bottomAction__1w9x2",
	"dropdownLeft": "WarehouseTransferPage_dropdownLeft__2hrzO"
};
module.exports = exports;
