// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".WarehouseOutboundPage_title__2O0Y1 {\n  margin: 0 20px 0 0;\n}\n.WarehouseOutboundPage_card__2xBm8 {\n  margin-top: 30px;\n  box-shadow: 0 1px 6px 0 rgba(49,53,59,0.12);\n}\n.WarehouseOutboundPage_flexContainer__2jzPm {\n  display: flex;\n  align-items: center;\n}\n.WarehouseOutboundPage_bottomAction__1Q_Ty {\n  display: flex;\n  justify-content: space-between;\n  margin-top: 40px;\n}\n.WarehouseOutboundPage_dropdownLeft__5yEFk {\n  margin-right: 15px;\n}", ""]);
// Exports
exports.locals = {
	"title": "WarehouseOutboundPage_title__2O0Y1",
	"card": "WarehouseOutboundPage_card__2xBm8",
	"flexContainer": "WarehouseOutboundPage_flexContainer__2jzPm",
	"bottomAction": "WarehouseOutboundPage_bottomAction__1Q_Ty",
	"dropdownLeft": "WarehouseOutboundPage_dropdownLeft__5yEFk"
};
module.exports = exports;
