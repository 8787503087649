// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ProductDetailHeader_card__3UvSq {\n  margin-top: 30px;\n  box-shadow: 0 1px 6px 0 rgba(49,53,59,0.12);\n}\n", ""]);
// Exports
exports.locals = {
	"card": "ProductDetailHeader_card__3UvSq"
};
module.exports = exports;
