// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".MainLayout_logo__XRAUf {\n  height: 32px;\n  margin: 16px;\n  background: rgba(255, 255, 255, 0.3);\n  text-align: center;\n  padding: auto;\n}\n.MainLayout_logo__XRAUf h3 {\n  color: white;\n  line-height: 32px;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n.MainLayout_header__7FY9_ {\n  padding: 0;\n}\n.MainLayout_contentLayout__1K4pE{\n  padding: 0 16px;\n}\n.MainLayout_userInfo__uzTP8 {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  margin: 40px 10px;\n}\n.MainLayout_userAvatar__3T-nA {\n  width: 50px;\n  height: 50px;\n  border-radius: 50%;\n  background-color: white;\n  margin-bottom: 10px;\n}\n.MainLayout_avatarText__2pafQ {\n  color: white;\n  margin: 0;\n  text-align: center;\n}\n.MainLayout_pageContainer__3VcWS {\n  min-height: 90vh;\n  max-width: 1136px;\n  margin: auto;\n}\n.MainLayout_sider__2NuX4 {\n  position: sticky;\n  position: -webkit-sticky;\n  top: 0;\n}", ""]);
// Exports
exports.locals = {
	"logo": "MainLayout_logo__XRAUf",
	"header": "MainLayout_header__7FY9_",
	"contentLayout": "MainLayout_contentLayout__1K4pE",
	"userInfo": "MainLayout_userInfo__uzTP8",
	"userAvatar": "MainLayout_userAvatar__3T-nA",
	"avatarText": "MainLayout_avatarText__2pafQ",
	"pageContainer": "MainLayout_pageContainer__3VcWS",
	"sider": "MainLayout_sider__2NuX4"
};
module.exports = exports;
