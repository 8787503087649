// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".WarehousePreparingPage_title__3SU84 {\n  margin: 0 20px 0 0;\n}\n.WarehousePreparingPage_card__IDjPw {\n  margin-top: 30px;\n  box-shadow: 0 1px 6px 0 rgba(49,53,59,0.12);\n}\n.WarehousePreparingPage_flexContainer__1rs7x {\n  display: flex;\n  align-items: center;\n  justify-content:space-around;\n}\n.WarehousePreparingPage_shopSelectorContainer__3Ceiu {\n  margin-left: 10px;\n}\n.WarehousePreparingPage_bottomAction__2dFpU {\n  display: flex;\n  justify-content: space-between;\n  margin-top: 40px;\n}\n.WarehousePreparingPage_dropdownLeft__3K8t7 {\n  margin-right: 15px;\n}\n.WarehousePreparingPage_tag__35CYr {\n  user-select: none;\n}\n", ""]);
// Exports
exports.locals = {
	"title": "WarehousePreparingPage_title__3SU84",
	"card": "WarehousePreparingPage_card__IDjPw",
	"flexContainer": "WarehousePreparingPage_flexContainer__1rs7x",
	"shopSelectorContainer": "WarehousePreparingPage_shopSelectorContainer__3Ceiu",
	"bottomAction": "WarehousePreparingPage_bottomAction__2dFpU",
	"dropdownLeft": "WarehousePreparingPage_dropdownLeft__3K8t7",
	"tag": "WarehousePreparingPage_tag__35CYr"
};
module.exports = exports;
