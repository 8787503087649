// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SuperAdminShopsPage_card__1c9EO {\n  margin: 10px 0px;\n  box-shadow: 0 1px 6px 0 rgba(49, 53, 59, 0.12);\n}\n\n.SuperAdminShopsPage_flexContainer__2b1AF {\n  display: flex;\n  justify-content: space-between;\n}\n", ""]);
// Exports
exports.locals = {
	"card": "SuperAdminShopsPage_card__1c9EO",
	"flexContainer": "SuperAdminShopsPage_flexContainer__2b1AF"
};
module.exports = exports;
