// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ProductsPage_title__2mn0R {\n  margin: 30px 0;\n}\n.ProductsPage_buttonAddOne__nE2-y {\n  margin-right: 5px;\n}\n.ProductsPage_flexContainer__3U0jh {\n  display: flex;\n  justify-content: space-between;\n}\n.ProductsPage_card__In_1L {\n  margin-top: 30px;\n  box-shadow: 0 1px 6px 0 rgba(49,53,59,0.12);\n}\n", ""]);
// Exports
exports.locals = {
	"title": "ProductsPage_title__2mn0R",
	"buttonAddOne": "ProductsPage_buttonAddOne__nE2-y",
	"flexContainer": "ProductsPage_flexContainer__3U0jh",
	"card": "ProductsPage_card__In_1L"
};
module.exports = exports;
