// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".WarehouseTransactionPage_title__2npHf {\n  margin: 0 20px 0 0;\n}\n.WarehouseTransactionPage_card__2S13P {\n  margin-top: 30px;\n  box-shadow: 0 1px 6px 0 rgba(49,53,59,0.12);\n}\n.WarehouseTransactionPage_flexContainer__2ud1M {\n  display: flex;\n  align-items: center;\n}\n.WarehouseTransactionPage_bottomAction__1e9Ui {\n  display: flex;\n  justify-content: space-between;\n  margin-top: 40px;\n}\n.WarehouseTransactionPage_select__2bPTm {\n  min-width: 100px;\n  max-width: 350px;\n}\n.WarehouseTransactionPage_prepSelector__3Td48 {\n  margin-left: 16px;\n}", ""]);
// Exports
exports.locals = {
	"title": "WarehouseTransactionPage_title__2npHf",
	"card": "WarehouseTransactionPage_card__2S13P",
	"flexContainer": "WarehouseTransactionPage_flexContainer__2ud1M",
	"bottomAction": "WarehouseTransactionPage_bottomAction__1e9Ui",
	"select": "WarehouseTransactionPage_select__2bPTm",
	"prepSelector": "WarehouseTransactionPage_prepSelector__3Td48"
};
module.exports = exports;
