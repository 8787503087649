// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ProductListComponent_card__2iF3E {\n  margin-top: 30px;\n  box-shadow: 0 1px 6px 0 rgba(49, 53, 59, 0.12);\n}\n\n.ProductListComponent_actionView__2sQh0 {\n  margin-right: 4px;\n}\n\n.ProductListComponent_search__254Jl {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-end;\n  margin: 30px 0px;\n}\n", ""]);
// Exports
exports.locals = {
	"card": "ProductListComponent_card__2iF3E",
	"actionView": "ProductListComponent_actionView__2sQh0",
	"search": "ProductListComponent_search__254Jl"
};
module.exports = exports;
