// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".WarehouseDemandPage_card__rrAKw {\n  margin-top: 30px;\n  box-shadow: 0 1px 6px 0 rgba(49, 53, 59, 0.12);\n}\n.WarehouseDemandPage_flexContainer__EhIn1 {\n  display: flex;\n  align-items: center;\n}\n.WarehouseDemandPage_title__3Yaw_ {\n  margin: 0 20px 0 0;\n}\n", ""]);
// Exports
exports.locals = {
	"card": "WarehouseDemandPage_card__rrAKw",
	"flexContainer": "WarehouseDemandPage_flexContainer__EhIn1",
	"title": "WarehouseDemandPage_title__3Yaw_"
};
module.exports = exports;
