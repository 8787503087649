// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".AdminDetailHeader_card__3snom {\n  margin-top: 30px;\n  box-shadow: 0 1px 6px 0 rgba(49, 53, 59, 0.12);\n}\n\n.AdminDetailHeader_header__10nZ9 {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n\n.AdminDetailHeader_title__1kOiW {\n  flex-grow: 2;\n}\n\n.AdminDetailHeader_button__2fUBz {\n  margin: 0px 10px;\n}\n", ""]);
// Exports
exports.locals = {
	"card": "AdminDetailHeader_card__3snom",
	"header": "AdminDetailHeader_header__10nZ9",
	"title": "AdminDetailHeader_title__1kOiW",
	"button": "AdminDetailHeader_button__2fUBz"
};
module.exports = exports;
