// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".inlineProductForm_formContainer__2rO6o {\n  margin: 20px 0;\n}", ""]);
// Exports
exports.locals = {
	"formContainer": "inlineProductForm_formContainer__2rO6o"
};
module.exports = exports;
