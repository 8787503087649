// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".LoginPage_container__15NU1 {\n  height: 100vh;\n  width: 100%;\n  display:flex;\n  justify-content: center;\n  align-items: center;\n  background-color: #002140;\n}\n.LoginPage_wrapper__2TPpD {\n  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);\n  border-radius: 0.5em;\n  padding: 36px 24px 10px;\n  color: #000000d9;\n  border: 1px solid #f0f0f0;\n  background-color: #eee;\n}\n.LoginPage_wrapper__2TPpD h3 {\n  text-align: center;\n}\n.LoginPage_formContainer__1jikC{\n  padding: 10px;\n  max-width: 600px;\n}\n.LoginPage_formItem__3gmx7 {\n  margin-bottom: 10px;\n}\n.LoginPage_submitButton__3HXZg {\n  background-color:#002140;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "LoginPage_container__15NU1",
	"wrapper": "LoginPage_wrapper__2TPpD",
	"formContainer": "LoginPage_formContainer__1jikC",
	"formItem": "LoginPage_formItem__3gmx7",
	"submitButton": "LoginPage_submitButton__3HXZg"
};
module.exports = exports;
